import dynamic from 'next/dynamic';

import { CarouselSliceProps } from '@ui/slice-machine/slices/carousel-slice/CarouselSlice';

const CarouselSlice = dynamic<CarouselSliceProps>(() =>
  import('@ui/slice-machine/slices/carousel-slice/CarouselSlice').then(
    (val) => val.CarouselSlice
  )
);

export default CarouselSlice;
